// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-go-messaging-js": () => import("./../../../src/pages/go/messaging.js" /* webpackChunkName: "component---src-pages-go-messaging-js" */),
  "component---src-pages-go-messaging-trace-opentelemetry-js": () => import("./../../../src/pages/go/messaging-trace/opentelemetry.js" /* webpackChunkName: "component---src-pages-go-messaging-trace-opentelemetry-js" */),
  "component---src-pages-hackathon-js": () => import("./../../../src/pages/hackathon.js" /* webpackChunkName: "component---src-pages-hackathon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-start-spring-io-help-js": () => import("./../../../src/pages/start-spring-io-help.js" /* webpackChunkName: "component---src-pages-start-spring-io-help-js" */),
  "component---src-pages-workshop-js": () => import("./../../../src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */)
}

